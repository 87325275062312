import React from 'react'

interface Props {
  url?: string;
  videoID?: string;
  className?: string;
}

export const Player = ({ videoID, className }: Props) => {
  // const host = 'http://rb-player.example.com';
  const host = 'http://localhost:4242';
  // const host = './player';
  const url_player=`<iframe src='${host}' id='video' videoID='${videoID}' allow="camera;microphone " />`;
  return (
    <div className={`player-wrapper video-container ${className}`}>
      <div dangerouslySetInnerHTML={{ __html: " "+url_player+" "}} />
    </div>
  )
}
